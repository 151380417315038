import React, { type FC, useCallback, useState } from 'react';
import { Button } from '@/components/ui/Button';
import { IconLogOut } from '@/components/ui/icons/IconLogOut';
import { useLogOut } from '@/controllers/user/user.hooks/useLogOut';
import styles from './LandingHeaderLogoutButton.module.scss';

export const LandingHeaderLogoutButton: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { logOut } = useLogOut();

  const userLogOut = useCallback(async () => {
    setIsLoading(true);

    try {
      await logOut();
    } catch {
      setIsLoading(false);
    }
  }, [logOut]);

  return (
    <Button
      mode={Button.mode.BrandPrimary}
      size={Button.size.Small}
      LeftIcon={IconLogOut}
      onClick={userLogOut}
      isLoading={isLoading}
      className={styles.logoutButton}
    />
  );
};
