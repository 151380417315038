import { type Domain } from '@/controllers/graphql/generated';
import { type DomainsQueryResult, useDomainsQuery } from '@/controllers/subDomain/graphql/generated/domains.query.generated';

interface UseDomains {
  (): [Domain[], DomainsQueryResult];
}

export const useDomains: UseDomains = () => {
  const domainsQuery = useDomainsQuery();

  return [domainsQuery.data?.domains ?? [], domainsQuery];
};
