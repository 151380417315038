import { useMemo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { type CourseListFragment } from '@/components/platform/Learn/graphql/generated/courseList.fragment.generated';
import {
  type GetCoursesListQueryVariables,
  useGetCoursesListQuery,
} from '@/components/platform/Learn/graphql/generated/getCoursesList.query.generated';
import { excludeEntitiesFromList } from '@/controllers/courses/courses.utils/excludeEntitiesFromList';

interface HookArgs extends GetCoursesListQueryVariables {
  skip?: boolean;
}

type HookResult = [CourseListFragment[], boolean];

type Hook = (args: HookArgs) => HookResult;

export const useCoursesList: Hook = (args) => {
  const { subDomain, language } = useSubDomainContext();
  const { skip } = args;

  const { data, loading } = useGetCoursesListQuery({
    variables: {
      domain: subDomain,
      lang: language,
      ...args,
    },
    skip,
  });

  const courses = useMemo(() => {
    const result = data?.coursesList && !loading
      ? data.coursesList
      : [];

    return excludeEntitiesFromList(result);
  }, [data, loading]);

  return [courses, loading];
};
