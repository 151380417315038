import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconTikTok: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.5941 6.69C18.5972 6.47079 17.6954 5.94144 17.0181 5.17789C16.3408 4.41435 15.9228 3.45587 15.8241 2.44V2H12.3741V15.67C12.3748 16.2766 12.1846 16.868 11.8305 17.3604C11.4764 17.8529 10.9763 18.2215 10.4011 18.414C9.8259 18.6065 9.20471 18.6131 8.62553 18.4329C8.04635 18.2527 7.53855 17.8948 7.17405 17.41C6.8475 16.9808 6.64754 16.4689 6.59678 15.932C6.54602 15.3951 6.64649 14.8548 6.88683 14.372C7.12717 13.8892 7.49778 13.4834 7.95676 13.2003C8.41575 12.9172 8.94478 12.7681 9.48405 12.77C9.78229 12.7683 10.079 12.8121 10.3641 12.9V9.4C10.0324 9.35893 9.69816 9.34221 9.36405 9.35C8.13336 9.38288 6.93897 9.77393 5.92714 10.4753C4.9153 11.1766 4.12994 12.1578 3.66723 13.2986C3.20451 14.4395 3.08453 15.6905 3.32197 16.8986C3.55941 18.1066 4.14396 19.2191 5.00405 20.1C5.88554 20.9962 7.01354 21.6101 8.24474 21.8638C9.47595 22.1175 10.7548 21.9994 11.9187 21.5246C13.0827 21.0498 14.0792 20.2397 14.7817 19.1973C15.4842 18.1548 15.8609 16.9271 15.8641 15.67V8.67C17.2555 9.66395 18.9241 10.1956 20.6341 10.19V6.79C20.2982 6.79139 19.963 6.75787 19.6341 6.69H19.5941Z" fill="currentColor" />
  </BaseIcon>
);
