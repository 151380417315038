import React, { type FC, useCallback, useState } from 'react';
import { Modal, PopUpTypeMode } from '@/components/ui/Modal';
import { typography } from '@/components/ui/typography';
import { CountrySelector } from '@/components/landing/CountrySelectorModal/CountrySelector';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';

interface Props {
  renderTriggerButton: (
    options: {
    onClick: () => void;
  }
  ) => React.ReactElement;
}
export const CountrySelectorModal: FC<Props> = (props) => {
  const { renderTriggerButton } = props;
  const { t } = useTranslation([I18N_CODES.countrySelector]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      {renderTriggerButton({
        onClick: openModal,
      })}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        type={PopUpTypeMode.Default}
        renderTitle={() => (
          <p className={typography.landingH5}>
            {t(`${I18N_CODES.countrySelector}:country_selector_title`)}
          </p>
        )}
      >
        <CountrySelector
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};
