import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconFlagUA: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect x="0" y="0" width="24" height="12" fill="#0057B8" />
      <rect x="0" y="12" width="24" height="12" fill="#FFD700" />
    </g>
  </BaseIcon>
);
