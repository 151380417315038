import React, { type FC } from 'react';
import { IconFacebook } from '@/components/ui/icons/IconFacebook';
import { IconTwitter } from '@/components/ui/icons/IconTwitter';
import { IconYoutube } from '@/components/ui/icons/IconYoutube';
import { IconLinkedIn } from '@/components/ui/icons/IconLinkedIn';
import { IconTikTok } from '@/components/ui/icons/IconTikTok';
import { IconInstagram } from '@/components/ui/icons/IconInstagram';
import { Button, type ButtonMode } from '@/components/ui/Button';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { type SUB_DOMAINS } from '@/middleware/i18n/i18n.config';
import { SubDomains } from '@/constants/subdomains';
import { IconTelegram } from '@/components/ui/icons/IconTelegram';

const ukrainianSocialMedias = [
  {
    id: 1, title: 'Facebook', link: 'https://www.facebook.com/mateacademy/', icon: IconFacebook,
  },
  {
    id: 2, title: 'Instagram', link: 'https://instagram.com/mate_academy', icon: IconInstagram,
  },
  {
    id: 3, title: 'Telegram', link: 'https://t.me/mate_academy_events', icon: IconTelegram,
  },
  {
    id: 4, title: 'Youtube', link: 'https://www.youtube.com/mateacademy?sub_confirmation=1', icon: IconYoutube,
  },
  {
    id: 5, title: 'TikTok', link: 'https://www.tiktok.com/@mate.academy?_t=8cltJwaFely&_r=1', icon: IconTikTok,
  },
  {
    id: 6, title: 'LinkedIn', link: 'https://www.linkedin.com/school/mateacademy/', icon: IconLinkedIn,
  },
  {
    id: 7, title: 'Twitter', link: 'https://twitter.com/MateAcademy', icon: IconTwitter,
  },
];

const internationalSocialMedias = [
  {
    id: 1, title: 'Facebook', link: 'https://www.facebook.com/mate.academy.int', icon: IconFacebook,
  },
  {
    id: 2, title: 'Instagram', link: 'https://www.instagram.com/mate.academy_int/', icon: IconInstagram,
  },
  {
    id: 3, title: 'LinkedIn', link: 'https://www.linkedin.com/school/mateacademy/', icon: IconLinkedIn,
  },
];

const polishSocialMedias = [
  {
    id: 1, title: 'Facebook', link: 'https://www.facebook.com/mateacademy.polska', icon: IconFacebook,
  },
  {
    id: 2, title: 'Instagram', link: 'https://www.instagram.com/mateacademy.pl', icon: IconInstagram,
  },
  {
    id: 3, title: 'Youtube', link: 'https://www.youtube.com/@Mateacademy_Polska', icon: IconYoutube,
  },
  {
    id: 4, title: 'TikTok', link: 'https://www.tiktok.com/@mate_academy_pl', icon: IconTikTok,
  },
  {
    id: 5, title: 'LinkedIn', link: 'https://www.linkedin.com/company/mate-academy-polska/', icon: IconLinkedIn,
  },
];

const brazilianSocialMedias = [
  {
    id: 1, title: 'Facebook', link: 'https://www.facebook.com/mate.academy.int', icon: IconFacebook,
  },
  {
    id: 2, title: 'Instagram', link: 'https://www.instagram.com/mate.academy_br/', icon: IconInstagram,
  },
  {
    id: 3, title: 'Youtube', link: 'https://www.youtube.com/@Mateacademy_Brasil', icon: IconYoutube,
  },
  {
    id: 4, title: 'LinkedIn', link: 'https://www.linkedin.com/company/mateacademy-brasil/', icon: IconLinkedIn,
  },
];

const getSocialMedias = (subDomain: SUB_DOMAINS) => {
  switch (subDomain) {
    case SubDomains.ua: {
      return ukrainianSocialMedias;
    }

    case SubDomains.pl: {
      return polishSocialMedias;
    }

    case SubDomains.br: {
      return brazilianSocialMedias;
    }

    default: {
      return internationalSocialMedias;
    }
  }
};

interface Props {
  buttonMode?: ButtonMode;
}
export const LandingSocialLinks: FC<Props> = (props) => {
  const { subDomain } = useSubDomainContext();

  const { buttonMode = Button.mode.TransparentLight } = props;

  const socialMedias = getSocialMedias(subDomain);

  return (
    <div>
      {socialMedias.map((item) => (
        <Button
          key={item.id}
          title={item.title}
          href={item.link}
          target="_blank"
          rel="nofollow noreferrer noopener"
          LeftIcon={item.icon}
          size={Button.size.Large}
          mode={buttonMode}
        />
      ))}
    </div>
  );
};
