import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconInstagram: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.1276 3H7.87244C5.18573 3 3 5.18573 3 7.87244V16.1277C3 18.8143 5.18573 21 7.87244 21H16.1277C18.8143 21 21 18.8143 21 16.1277V7.87244C21 5.18573 18.8143 3 16.1276 3V3ZM12 16.9217C9.2861 16.9217 7.07826 14.7139 7.07826 12C7.07826 9.2861 9.2861 7.07826 12 7.07826C14.7139 7.07826 16.9217 9.2861 16.9217 12C16.9217 14.7139 14.7139 16.9217 12 16.9217ZM17.0394 8.23897C16.2374 8.23897 15.5851 7.58665 15.5851 6.78465C15.5851 5.98265 16.2374 5.3302 17.0394 5.3302C17.8414 5.3302 18.4939 5.98265 18.4939 6.78465C18.4939 7.58665 17.8414 8.23897 17.0394 8.23897Z" fill="currentColor" />
  </BaseIcon>
);
